<template>
  
      <f7-link class="bck-btn-nav" @click.stop="$h.goBack()">
        <font-awesome-icon :icon="['far', 'arrow-left']" fixed-width />
      </f7-link>
    
    
</template>

<script>
import { defineComponent, computed  } from "vue";
import { useStore } from "@/store";
import { configs } from "@/utils/configs.js";

import { f7 } from "framework7-vue";


export default defineComponent({
  name: "ProductNavigationComponent",
  components: {
    },
  props: {
   
  },
  emits: ["searched"],
  setup(props, { emit }) {
    const store = useStore();

    const $f7router = f7.views.get("#main-view").router;

    const activeLink = computed(() => store.getters["page/getActiveLink"]);

    const back = () => {
      if (props?.parentOrderKey) {
        window.location.href = "/profile/order/sub/?ParentOrderKey=" + props.parentOrderKey;
      } else {
        window.location.href = "/profile/order/?mode=all";
      }
    };

    return {
      activeLink,
      back,
      store,
    };
  },
});
</script>

<style scoped>
a.link.bck-btn-nav {
    position: absolute;
    top: 35px;
    left: 50px;
    height: 45px;
    width: 45px;
    z-index: 500;
    background-color: rgb(17 17 17 / 40%);
    border-radius: 50%;
    font-weight: 700;
    font-size: 20px;
    color: #fff;
}
</style>
